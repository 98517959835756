import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from '../_store/actions'
import messages from '../_store/modules/messages'
// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    actions,
    modules: {
        messages,
    },
    strict: debug,
    // plugins: debug ? [createLogger()] : []
});
