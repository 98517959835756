<template>

    <div id="app">
        <div class="page-layout">

            <div class="page-container uk-background-muted">
                <div class="uk-padding uk-padding-remove-left uk-padding-remove-right uk-position-relative" uk-height-viewport="offset-top: true">
                    <div class="uk-container">

                        <div class="uk-margin-medium-top">

                            <div class="uk-margin-small-bottom uk-text-center">
                                <div class="uk-navbar-item uk-logo"><img :src="cdnUrl + '/img/reminderbot.svg'" alt="Reminder Bot"></div>
                            </div>


                            <router-view></router-view>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import {cdnUrl} from "../helpers";

    export default {
        name: 'app',

        computed: {

            cdnUrl() {
                return cdnUrl;
            }

        },

        created() {

            this.$store.dispatch('messages/clearMessages')
                .then(() => {

                    // Check for error messages
                    if(Object.keys(window.pageErrors).length > 0) {
                        for(const key of Object.keys(window.pageErrors)) {

                            for(const i  in window.pageErrors[key]) {
                                this.$store.dispatch('messages/showMessage', {
                                    type: 'error',
                                    message: window.pageErrors[key][i],
                                });
                            }

                        }
                    }

                });


        }
    }

</script>
