
// Account
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_ACCOUNT_USER = 'SET_ACCOUNT_USER';
export const SET_ACCOUNT_IMPERSONATING = 'SET_ACCOUNT_IMPERSONATING';
export const SET_ACCOUNT_OWNER = 'SET_ACCOUNT_OWNER';
export const SET_USER_VERIFIED = 'SET_USER_VERIFIED';
export const SET_USE_TEAM_REMINDERS = 'SET_USE_TEAM_REMINDERS';
export const SET_USE_USER_REMINDERS = 'SET_USE_USER_REMINDERS';

// Countries
export const SET_GEO_IP_COUNTRY = 'SET_GEO_IP_COUNTRY';
export const SET_GEO_IP_ADDRESS = 'SET_GEO_IP_ADDRESS';

// Plans
export const SET_PLANS = 'SET_PLANS';
export const SET_ACCOUNT_PLAN = 'SET_ACCOUNT_PLAN';

// Reminders
export const SET_REMINDERS = 'SET_REMINDERS';

// Teams
export const SET_TEAMS = 'SET_TEAMS';

// Team Reminders
export const SET_TEAM_REMINDERS = 'SET_TEAM_REMINDERS';

// Timezones
export const SET_CURRENT_TIMEZONE = 'SET_CURRENT_TIMEZONE';

// Connections
export const SET_CONNECTIONS = 'SET_CONNECTIONS';

// Messages
export const SET_MESSAGES = 'SET_MESSAGES';
export const ADD_MESSAGE = 'ADD_MESSAGE';

// Registration
export const SET_REGISTRATION_INVITE = 'SET_REGISTRATION_INVITE';
export const SET_REGISTRATION_ACCOUNT = 'SET_REGISTRATION_ACCOUNT';
export const SET_REGISTRATION_PLAN = 'SET_REGISTRATION_PLAN';
export const SET_REGISTRATION_TERMS_OF_SERVICE = 'SET_REGISTRATION_TERMS_OF_SERVICE';
export const SET_REGISTRATION_REMINDER = 'SET_REGISTRATION_REMINDER';

