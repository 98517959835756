import Vue from 'vue'
import VueRouter from 'vue-router';


// Authentication
const Login              = () => import('./components/Login');
const ForgotPassword     = () => import('./components/ForgotPassword');
const ResetPassword      = () => import('./components/ResetPassword');

// Errors
const Error404           = () => import('./components/Error404');


// Export routes
Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [

        // Authentication
        { name: 'login',                    path: '/login',                 component: Login },
        { name: 'forgotPassword',           path: '/password/reset/:token', component: ResetPassword },
        { name: 'passwordReset',            path: '/password/reset',        component: ForgotPassword },

        // Registration
        { name: 'registrationConnections',  path: '/sign-up' },

        // Errors
        { name: '404',                      path: '/404',                   component: Error404 },
        { name: 'CatchAllError',            path: '/*',                     component: Error404 },

    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});
