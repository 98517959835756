<template>
    <svg-vue
        :icon="this.path" v-if="this.path"
        :class="'svg-inline--fa fa-' + (this.size ? this.size : '1x')"
    />
</template>

<script>

export default {

    props: [
        'icon',
        'size',
        // TODO: 'class',
    ],

    data() {
        return {
            path: '',
        }
    },

    created() {

        // Validate the props
        let style = typeof this.icon[0] === 'string' && this.icon[0] ? this.icon[0] : '';
        let name = typeof this.icon[1] === 'string' && this.icon[1] ? this.icon[1] : '';

        if (!style || !name) {
            return;
        }


        // Convert the style
        let path = '';
        switch (style) {

            case 'far':
                path = 'regular-';
                break;

            case 'fas':
                path = 'solid-';
                break;

            case 'fal':
                path = 'light-';
                break;

            case 'fab':
                path = 'brands-';
                break;

        }

        if (!path) {
            return;
        }


        // Dynamically import path
        path += name;
        this.path = 'fontawesome/' + path;
    }

}

</script>
