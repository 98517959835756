<template>

    <div>

        <!-- TODO: Group up message types so that a maximum of 3 uk-alerts will show -->
        <div v-show="messages && messages.length > 0"
             v-for="message in messages"
             class="uk-alert"
             :class="{
                'uk-alert-success': message.type === 'success',
                'uk-alert-warning': message.type === 'warning',
                'uk-alert-danger': message.type === 'error'
             }">

            <span>{{ message.message }}</span>
        </div>

    </div>

</template>

<script>
    import { mapGetters } from 'vuex'

    export default {

        computed: {

            ...mapGetters({
                messages: 'messages/getMessages'
            })

        }

    }

</script>
