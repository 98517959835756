import Vue from 'vue'
import PageMessagesView from '../../_layouts/components/PageMessagesView'
import LoadError from '../../_layouts/components/LoadError'
import PageSpinner from '../../_layouts/components/PageSpinner'
import FontAwesomeIcon from "../../_layouts/components/FontAwesomeIcon";

// Components
Vue.component('page-messages', PageMessagesView);
Vue.component('load-error', LoadError);
Vue.component('page-spinner', PageSpinner);
Vue.component('font-awesome-icon', FontAwesomeIcon);
