require('./../sentry');
require('./bootstrap');
import Vue from 'vue';
import VueGtag from "vue-gtag";
import SvgVue from 'svg-vue';

import { default as App } from './App.vue'
import './layout';

import router from './router'
import store from './store'
import './validation'


if(typeof window.googleAnalyticsKey !== 'undefined' && window.googleAnalyticsKey) {
    Vue.use(VueGtag, {
        config: { id: window.googleAnalyticsKey }
    });
}


if(document.getElementById('app') !== null) {
    Vue.use(SvgVue);
    new Vue({
        el: '#app',
        store,
        router,
        template: '<App/>',
        components: { App }
    });
}
