import * as Sentry from "@sentry/browser";


export const cdnUrl = typeof window.cdnUrl === 'string' && window.cdnUrl ? window.cdnUrl : '';


export const reportAxiosError = (error) => {

    if (!error) {
        Sentry.captureMessage('Empty Axios Error Object');
        return;
    }

    if (typeof error === 'object' && typeof error.message === 'string' && error.message == 'Network Error') {
        return;
    }


    // Report error
    Sentry.captureException(error, {
        extra: {
            data: typeof error.response === 'object' && typeof error.response.data !== 'undefined' ? JSON.stringify(error.response.data) : null,
            error: JSON.stringify(error),
        }
    });
};


export const getCSRFToken = () => {
    let token = document.head.querySelector('meta[name="csrf-token"]');

    if(!token) {
        return '';
    }

    return token.content;
};

export const colours = {

    // Basic Colours
    white:          '#fff',
    black:          '#111',
    grey:           '#eaeaea',
    blue:           '#439DDF',
    darkBlue:       '#30546a',
    darkestBlue:    '#203847',
    green:          '#7dd640',
    orange:         '#faa05a',
    red:            '#f0506e',

    // Specific Colours
    borderGrey: '#eaeaea',
    textColor: '#666',

};

export const getConnectionTitle = (key) => {
    switch(key) {

        case 'sms':
            return 'Text Message';

        case 'slack':
            return 'Slack';

        case 'google_calendar': // DEPRECATED
            return 'Google Calendar';

        case 'google_hangouts_chat':
            return 'Google Chat';

        case 'outlook_calendar': // DEPRECATED
            return 'Outlook Calendar';

        case 'facebook': // DEPRECATED
            return 'Facebook Messenger';

        case 'telegram': // DEPRECATED
            return 'Telegram';

        case 'discord':
            return 'Discord';

        case 'microsoft_teams':
            return 'Microsoft Teams';

    }

    return 'Unknown';
};

export const getResponseTitle = (key) => {
    switch(key) {

        case 'url':
            return 'Website Response';

        case 'manual':
            return 'Website Response';

        case 'sms':
            return 'Text Message';

        case 'slack':
            return 'Slack';

        case 'facebook_message':
            return 'Facebook Messenger';

        case 'telegram':
            return 'Telegram';

        case 'google_hangouts_chat':
            return 'Google Chat';

        case 'discord':
            return 'Discord';

        case 'microsoft_teams':
            return 'Microsoft Teams';

    }

    return 'Unknown';
};

export const getConnectionRouterName = (key) => {
    switch(key) {

        case 'sms':
            return 'smsConnection';

        case 'slack':
            return 'slackConnection';

        case 'google_hangouts_chat':
            return 'googleHangoutsChatConnection';

        case 'discord':
            return 'discordConnection';

        case 'microsoft_teams':
            return 'microsoftTeamsConnection';

    }

    return '';
};

export const getReminderCycleStatusLabel = (isComplete, isActive, isScheduled, forceStopped, forceStoppedReason) => {
    let status  = '';
    let label   = '';

    if(forceStopped && forceStoppedReason) {
        switch(forceStoppedReason) {

            case 'reminder_deactivated':
                status  = 'deactivated';
                label   = 'Deactivated';
                break;

            case 'automatic_end':
                status  = 'ignored';
                label   = 'Ignored';
                break;

            case 'reminder_cycle_replaced':
            case 'user_skipped':
            case 'user_rescheduled':
                status  = 'rescheduled';
                label   = 'Rescheduled';
                break;

        }

    } else if(isComplete) {
        status  = 'complete';
        label   = 'Complete';

    } else if(isActive) {
        status  = 'in-progress';
        label   = 'In Progress';

    } else if(isScheduled) {
        status  = 'scheduled';
        label   = 'Scheduled';
    }

    return {
        status: status,
        label: label,
    };
};
