import _ from 'lodash';
import * as types from "../mutation-types";


const state = {

    messages: [],

};

const getters = {

    getMessages: state => state.messages,

};

const actions = {

    showMessage ({ commit, state }, payload) {

        if(payload.clear !== false) {
            commit(types.SET_MESSAGES, []);
        }

        commit(types.ADD_MESSAGE, {
            'type': payload.type,
            'message': payload.message,
        });
    },

    handleXHRFailureMessages ({ commit, state }, payload) {
        let errorShown = false;
        commit(types.SET_MESSAGES, []);

        console.log(payload);

        if(typeof payload.response === 'object' && payload.response) {

            // Validation errors
            if(payload.response.status === 422) { // payload.response.status === 200

                if(typeof payload.response.data.errors !== 'undefined') {
                    _.forOwn(payload.response.data.errors, function(errors, key) {
                        for(let i in errors) {
                            commit(types.ADD_MESSAGE, {
                                type: 'error',
                                message: errors[i],
                            });

                            errorShown = true;
                        }
                    });
                }

                if(!errorShown && typeof payload.response.data.message === 'string' && payload.response.data.message && payload.response.data.code !== 0) {
                    commit(types.ADD_MESSAGE, {
                        type: payload.response.data.result ? 'success' : 'error',
                        message: payload.response.data.message,
                    });

                    errorShown = true;
                }

            }


            // Authentication errors
            if(!errorShown && payload.response.status === 401) {
                window.location.href = '/logout';
                return;
            }
        }

        if(!errorShown) {
            commit(types.ADD_MESSAGE, {
                type: 'error',
                message: 'An unexpected error has occurred, please refresh your page and attempt again'
            });
        }
    },

    clearMessages ({ commit, state }) {
        commit(types.SET_MESSAGES, []);
    },

};

const mutations = {

    [types.SET_MESSAGES] (state, items) {
        state.messages = items
    },

    [types.ADD_MESSAGE] (state, item) {
        state.messages.push(item);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
